import React from "react";
import { graphql, Link } from "gatsby";
import "../scss/news-article-page.scss";
import 'uikit/dist/css/uikit.css'

import Layout from "../components/layout";
import SEO from "../components/seo";
import CustomMarkdown from "../utils/customMarkdown"
import Img from "gatsby-image";
import GetImgUrl from "../utils/customFunctions";
import { removeSlashFromSlug } from '../utils/additionalFunctions';
import loadable from '@loadable/component'

const QuoteCarousel = loadable(() => import("../components/quote-carousel"))

export const query = graphql`
  query newsArticlePagesQuery($id: Int) {
    strapiNewsArticle(strapiId: { eq: $id }) {
      SEOTitle
      SEODescription
      Canonical
      MetaTags{
        MetaName
        MetaContent
      }
      Components
      Title
      id
      slug
      Header {
        RightBgColor
        LeftBgColor
        Title
        Subtitle
        Visible
        CTA
        URL
        CTASecondary
        URLSecondary
        Background {
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;


const newsArticle = ({ data }) => {
  var headerData = {
    headerTitle: data.strapiNewsArticle.Header.Title
      ? data.strapiNewsArticle.Header.Title
      : "",
    headerDescription: data.strapiNewsArticle.Header.Subtitle
      ? data.strapiNewsArticle.Header.Subtitle
      : "",
    headerCTAPrimaryLabel: data.strapiNewsArticle.Header.CTA
      ? data.strapiNewsArticle.Header.CTA
      : "",
    headerCTAPrimaryUrl: data.strapiNewsArticle.Header.URL
      ? data.strapiNewsArticle.Header.URL
      : "",
    headerCTASecondaryLabel: data.strapiNewsArticle.Header.CTASecondary
      ? data.strapiNewsArticle.Header.CTASecondary
      : "",
    headerCTASecondaryUrl: data.strapiNewsArticle.Header.URLSecondary
      ? data.strapiNewsArticle.Header.URLSecondary
      : "",
    headerBgImage: data.strapiNewsArticle.Header.Background?.localFile.childImageSharp.fluid||{src:process.env.GATSBY_DEFAULT_NA_IMAGE},
    headerRightBgColor: data.strapiNewsArticle.Header.RightBgColor ? data.strapiNewsArticle.Header.RightBgColor : '',
    headerLeftBgColor: data.strapiNewsArticle.Header.LeftBgColor ? data.strapiNewsArticle.Header.LeftBgColor : '',

  };


  const url ="https://mindfulmemorials.co.uk";

  return (
    <div>
      <Layout
        pageData={headerData}
        headerShow={data.strapiNewsArticle.Header.Visible}
        shareOnSocial={true}
      >
        <SEO title={data.strapiNewsArticle.SEOTitle?data.strapiNewsArticle.SEOTitle:data.strapiNewsArticle.Title} 
        description={data.strapiNewsArticle.SEODescription} 
        meta={data.strapiNewsArticle.MetaTags}
        canonical={data.strapiNewsArticle.Canonical ? data.strapiNewsArticle.Canonical : `${url + '/news/' + removeSlashFromSlug(data.strapiNewsArticle.slug)}`}
        />
        <div className="news-article-page padding-top padding-bottom lateral-padding">
          {data.strapiNewsArticle.Components.map((item, index) => {
            return (
              <div key={index}>
                <CustomMarkdown children={item.Text} />
                
                {item.ImageCarousel ? (
                  <div className="event-page-image uk-height-medium uk-cover-container uk-margin-none">
                    <Img
                      fluid={
                        GetImgUrl(item.ImageCarousel.Images.localFile___NODE)
                          .fluid
                      }
                      alt="image"
                    ></Img>
                  </div>
                ) : null}

                {item.Links ? (
                  <Link to={item.Links.URL} className="event-page-link">
                    {item.Links.Link}
                  </Link>
                ) : null}

                {item.Testimonial 
                  ? (<div className="">
                      <QuoteCarousel quoteList={item.Testimonial}></QuoteCarousel>
                    </div>
                    ) 
                  : (
                    ""
                  )}
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};

export default newsArticle;
